/**
 * @desc
 */
 const ROOT = window
 let _Base64 = window.Base64
 let buffer
 const version = '2.1.9'
 const b64chars =
   'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789+/'
 
 if (typeof module !== 'undefined' && module.exports) {
   try {
     buffer = require('buffer').Buffer
   } catch (err) {}
 }
 let b64tab = (function(bin) {
   let t = {}
   for (let i = 0, l = bin.length; i < l; i++) {
     t[bin.charAt(i)] = i
   }
   return t
 })(b64chars)
 let fromCharCode = String.fromCharCode
 let cb_utob = function(c) {
   if (c.length < 2) {
     let cc = c.charCodeAt(0)
     return cc < 128
       ? c
       : cc < 2048
         ? fromCharCode(192 | (cc >>> 6)) + fromCharCode(128 | (cc & 63))
         : fromCharCode(224 | ((cc >>> 12) & 15)) +
         fromCharCode(128 | ((cc >>> 6) & 63)) +
         fromCharCode(128 | (cc & 63))
   } else {
     let cc =
       65536 + (c.charCodeAt(0) - 55296) * 1024 + (c.charCodeAt(1) - 56320)
     return (
       fromCharCode(240 | ((cc >>> 18) & 7)) +
       fromCharCode(128 | ((cc >>> 12) & 63)) +
       fromCharCode(128 | ((cc >>> 6) & 63)) +
       fromCharCode(128 | (cc & 63))
     )
   }
 }
 const re_utob = /[\uD800-\uDBFF][\uDC00-\uDFFFF]|[^\x00-\x7F]/g
 let utob = function(u) {
   return u.replace(re_utob, cb_utob)
 }
 let cb_encode = function(ccc) {
   let padlen = [0, 2, 1][ccc.length % 3]
   let ord =
     (ccc.charCodeAt(0) << 16) |
     ((ccc.length > 1 ? ccc.charCodeAt(1) : 0) << 8) |
     (ccc.length > 2 ? ccc.charCodeAt(2) : 0)
   let chars = [
     b64chars.charAt(ord >>> 18),
     b64chars.charAt((ord >>> 12) & 63),
     padlen >= 2 ? '=' : b64chars.charAt((ord >>> 6) & 63),
     padlen >= 1 ? '=' : b64chars.charAt(ord & 63)
   ]
   return chars.join('')
 }
 let btoa = window.btoa
   ? function(b) {
     return window.btoa(b)
   }
   : function(b) {
     return b.replace(/[\s\S]{1,3}/g, cb_encode)
   }
 let _encode = buffer
   ? function(u) {
     return (u.constructor === buffer.constructor
       ? u
       : new buffer(u)).toString('base64')
   }
   : function(u) {
     return btoa(utob(u))
   }
 let encode = function(u, urisafe) {
   return !urisafe
     ? _encode(String(u))
     : _encode(String(u)).
       replace(/[+\/]/g, function(m0) {
         return m0 == '+' ? '-' : '_'
       }).replace(/=/g, '')
 }
 let encodeURI = function(u) {
   return encode(u, true)
 }
 let re_btou = new RegExp(
   ['[À-ß][-¿]', '[à-ï][-¿]{2}', '[ð-÷][-¿]{3}'].join('|'),
   'g'
 )
 let cb_btou = function(cccc) {
   switch (cccc.length) {
     case 4:
       var cp =
           ((7 & cccc.charCodeAt(0)) << 18) |
           ((63 & cccc.charCodeAt(1)) << 12) |
           ((63 & cccc.charCodeAt(2)) << 6) |
           (63 & cccc.charCodeAt(3)),
         offset = cp - 65536
       return (
         fromCharCode((offset >>> 10) + 55296) +
         fromCharCode((offset & 1023) + 56320)
       )
     case 3:
       return fromCharCode(
         ((15 & cccc.charCodeAt(0)) << 12) |
           ((63 & cccc.charCodeAt(1)) << 6) |
           (63 & cccc.charCodeAt(2))
       )
     default:
       return fromCharCode(
         ((31 & cccc.charCodeAt(0)) << 6) | (63 & cccc.charCodeAt(1))
       )
   }
 }
 let btou = function(b) {
   return b.replace(re_btou, cb_btou)
 }
 let cb_decode = function(cccc) {
   let len = cccc.length,
     padlen = len % 4,
     n =
       (len > 0 ? b64tab[cccc.charAt(0)] << 18 : 0) |
       (len > 1 ? b64tab[cccc.charAt(1)] << 12 : 0) |
       (len > 2 ? b64tab[cccc.charAt(2)] << 6 : 0) |
       (len > 3 ? b64tab[cccc.charAt(3)] : 0),
     chars = [
       fromCharCode(n >>> 16),
       fromCharCode((n >>> 8) & 255),
       fromCharCode(n & 255)
     ]
   chars.length -= [0, 0, 2, 1][padlen]
   return chars.join('')
 }
 let atob = ROOT.atob
   ? function(a) {
     return ROOT.atob(a)
   }
   : function(a) {
     return a.replace(/[\s\S]{1,4}/g, cb_decode)
   }
 let _decode = buffer
   ? function(a) {
     return (a.constructor === buffer.constructor
       ? a
       : new buffer(a, 'base64')).toString()
   }
   : function(a) {
     return btou(atob(a))
   }
 let decode = function(a) {
   return _decode(
     String(a).
       replace(/[-_]/g, function(m0) {
         return m0 == '-' ? '+' : '/'
       }).
       replace(/[^A-Za-z0-9\+\/]/g, '')
   )
 }
 let noConflict = function() {
   let Base64 = ROOT.Base64
   ROOT.Base64 = _Base64
   return Base64
 }
 const Base64 = {
   VERSION: version,
   atob: atob,
   btoa: btoa,
   fromBase64: decode,
   toBase64: encode,
   utob: utob,
   encode: encode,
   encodeURI: encodeURI,
   btou: btou,
   decode: decode,
   noConflict: noConflict
 }
 
 // Base64.extendString = function() {
 //   Object.defineProperty(
 //     String.prototype,
 //     'fromBase64',
 //     noEnum(function() {
 //       return decode(this)
 //     })
 //   )
 //   Object.defineProperty(
 //     String.prototype,
 //     'toBase64',
 //     noEnum(function(urisafe) {
 //       return encode(this, urisafe)
 //     })
 //   )
 //   Object.defineProperty(
 //     String.prototype,
 //     'toBase64URI',
 //     noEnum(function() {
 //       return encode(this, true)
 //     })
 //   )
 // }
 export default Base64
 