export const UA = window.navigator.userAgent

export const isAndroid = /Android|HTC/i.test(UA)

export const isIPad = !isAndroid && (/iPad/i.test(UA) || (navigator.platform === 'MacIntel' && navigator.maxTouchPoints > 1)) && !window.MSStream

export const isIPhone = !isAndroid && /iPod|iPhone/i.test(UA)

export const isIOS = isIPad || isIPhone

export function isWeiXin(): boolean {
  const ua = window.navigator.userAgent.toLowerCase()
  const match = ua.match(/MicroMessenger/i)
  if (match === null) {
      return false
  }
  if (match.includes('micromessenger')) {
      return true
  }
  return false
}