/**
 * @desc js bridge for kewl
 */
import Base64 from './base64.js'
import { isIOS } from '@/utils/Device'

const ROOT = window
const DOC = ROOT.document
const UA = ROOT.navigator.userAgent

const IsAndroid = /Android|HTC/i.test(UA)
const IsIPad = !IsAndroid && (/iPad/i.test(UA) || (navigator.platform === 'MacIntel' && navigator.maxTouchPoints > 1)) && !window.MSStream
const IsIPhone = !IsAndroid && /iPod|iPhone/i.test(UA)
const IsIOS = IsIPad || IsIPhone

const IsNewIosApp =
  typeof ROOT.webkit === 'object' &&
  !!ROOT.webkit.messageHandlers &&
  !!ROOT.webkit.messageHandlers.webViewApp &&
  !!ROOT.webkit.messageHandlers.webViewApp.postMessage &&
  typeof ROOT.webkit.messageHandlers.webViewApp.postMessage === 'function'
// IosPostMessage = IsNewIosApp ? ROOT.webkit.messageHandlers.webViewApp.postMessage : null,
const IsKEWLApp =
  (IsAndroid &&
    typeof ROOT.android === 'object' &&
    ROOT.android.hasOwnProperty('openBoZhuPage')) ||
  !!IsNewIosApp;

const KEWLObj =
  IsKEWLApp && typeof ROOT.android === 'object' ? ROOT.android : null

// bridge回调
function asyncCallbackName(callback) {
  let fnName =
    packageName +
    new Date().getTime() +
    '' +
    Math.random().
      toString().
      substr(2)
  ROOT[fnName] = function(data) {
    let obj = '';
    if(typeof data === 'string') {
      try {
        obj = JSON.parse(data)
      } catch(error){
        obj = data
      }
    } else {
      obj = data
    }
    // let obj = typeof data === 'string' ? JSON.parse(data) : data
    callback && callback(obj)
    delete ROOT[fnName]
  }
  return fnName
}

const KEWLApp = {
//  kewlversion: function() {
//    return '0.1.1'
//  },
  UAInfo: {
    UA,
    IsAndroid,
    IsIPad,
    IsIPhone,
    IsIOS,
    IsNewIosApp,
    IsKEWLApp
  },
  tryOpen: function(url) {
  //  if (IsIOS) {
    //  if (!url && url !== '') {
    //    return
    //  }
    // //  url = PREFIX + url
    //  if (IsIOS) {
    //    ROOT.location.href = url
    //    return
    //  }
    //  let frame = DOC.createElement('iframe')
    //  frame.style.cssText = 'width:1px;height:1px;position:fixed;top:0;left:0;'
    //  frame.src = url
    //  DOC.body.appendChild(frame)
    //  setTimeout(function() {
    //    DOC.body.removeChild(frame)
    //  }, 150)
  //  }
    // this.tryIntentOpen(url)
  },
 /**
  * 打开webview
  */
  tryIntentOpen: function(url) {
    let anchorEle = DOC.createElement('a')
    let anchorHref = `jsb://${url}`;
    anchorEle.style.cssText = 'width:1px;height:1px;position:fixed;top:0;left:0;'
    anchorEle.href = anchorHref
    anchorEle.id = 'appIntent'
    DOC.body.appendChild(anchorEle)
    document.getElementById('appIntent').click()
    DOC.body.removeChild(anchorEle)
  },
  iosPostMessage: function(obj) {
    if (IsNewIosApp && typeof obj === 'object') {
      ROOT.webkit.messageHandlers.webViewApp.postMessage(obj)
    }
  },
 /**
  * 下载App、唤起
  */
  downloadApp() {
    if (isIOS) {
      window.location.href = 'https://apps.apple.com/cn/app/photogrid-%E7%85%A7%E7%89%87%E7%BB%84%E5%90%88-%E8%A7%86%E9%A2%91%E6%8B%BC%E8%B4%B4/id543577420'
    } else {
      let anchorEle = document.createElement('a')
      let anchorHref = 'pgcn://photogrid.com.cn/openapp';
      anchorEle.style.cssText = 'width:1px;height:1px;position:fixed;top:0;left:0;'
      anchorEle.href = anchorHref
      anchorEle.id = 'appDownload'
      document.body.appendChild(anchorEle);
      document.getElementById('appDownload').click();
      const startTime = new Date().valueOf()
      const timer = 1500;
      setTimeout(function() {
          const endTime = new Date().valueOf();
          document.body.removeChild(anchorEle);
          if (endTime - startTime < 2000) {
            window.location.href = '/download/PhotoGrid_Website_Release.apk'
          }
      }, timer);
    }
  },
 /**
  * 返回首页
  */
  openSudokuGuidePages() {
    this.tryIntentOpen('common/home')
  },
 /**
  * 生成app分享页
  */
  generateAppVipShare(index) {
    this.tryIntentOpen(`common/shareFriends?imageIndex=${index}`)
  }
}

Object.defineProperties(KEWLApp, {
  isKEWLApp: {
    get: function() {
      return IsKEWLApp
    }
  }
})
if (IsKEWLApp) {
  document.addEventListener(
    'DOMContentLoaded',
    function() {
      KEWLApp.setTitle(document.title)
    },
    false
  )
}
 
 window.Base64 = Base64;
 window.KEWLApp = KEWLApp;
 
 export { Base64, KEWLApp }
 